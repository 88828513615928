//= require jquery3
//= require jquery_ujs
//= require popper
//= require bootstrap
//= require_self

import "core-js/stable"
import "regenerator-runtime/runtime"

import "jquery"
import "jquery-ujs"
import "bootstrap"

import "../stylesheets/login"

window.$ = $;
window.jQuery = $;

$(function() {
  $('[data-error]').each(function() {
    var $this = $(this);
    $this.tooltip({
      title: $this.data('error')
    });
  });
});


// Restricts input for the set of matched elements to the given inputFilter function.
(function($) {
  $.fn.inputFilter = function(inputFilter) {
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  };
}(jQuery));

